import "./bootstrap"
import "./font-awesome"
import "../css/app.css"

import { createApp, h } from "vue"
import { createInertiaApp } from "@inertiajs/inertia-vue3"
import { InertiaProgress } from "@inertiajs/progress"
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers"
import { trail } from "momentum-trail"
import routes from "./Routes/routes.json"
import { RefinePlugin } from "@hammerstone/refine-vue3"
import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"

// FA
const appName = window.document.getElementsByTagName("title")[0]?.innerText || "Laravel"

let vueApp = null

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => {
        return resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob("./Pages/**/*.vue"))
    },
    setup({ el, app, props, plugin }) {
        // eslint-disable-next-line vue/component-api-style
        vueApp = createApp({ render: () => h(app, props) })
            .use(plugin)
            .use(trail, { routes })
            .use(RefinePlugin, {
                showLocators: true,
            })
            .mount(el)
    },
})

// Sentry.init({
//     vueApp,
//     dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
//     environment: "production",
//     tracesSampleRate: 0.05,
//     trackComponents: true,
//     logErrors: true,
//     integrations: [new BrowserTracing()],
// })

InertiaProgress.init({ color: "#4B5563" })

// Override CTRL + S from saving page contentss, we will instead be using it to save form data
document.addEventListener(
    "keydown",
    function (e) {
        if (e.key === "s" && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
            e.preventDefault()
        }
    },
    false
)
