import { library } from "@fortawesome/fontawesome-svg-core"

/* import specific icons */
import { faEarth, faPenToSquare } from "@fortawesome/pro-regular-svg-icons"
import {
    faBrowser,
    faListCheck,
    faAddressBook,
    faQuestionCircle,
    faSchool,
    faGraduationCap,
    faBolt,
    faLandmark,
    faFiles,
    faUsers,
    faList,
    faFileChartColumn,
    faCog,
} from "@fortawesome/pro-duotone-svg-icons"

/* add icons to the library */
library.add(faEarth)
library.add(faPenToSquare)

library.add(faBrowser)
library.add(faListCheck)
library.add(faAddressBook)
library.add(faQuestionCircle)
library.add(faSchool)
library.add(faGraduationCap)
library.add(faBolt)
library.add(faLandmark)
library.add(faFiles)
library.add(faUsers)
library.add(faList)
library.add(faFileChartColumn)
library.add(faCog)
